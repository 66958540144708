<template>
    <div class="cardReturn">
        <div class="left-box">
            <div class="title">会员信息</div>
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no"><input type="text" keyBoard placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Bestech_Card_KindName" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.MemberName" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Mobile" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Birthday" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Gender"/></div>
                </div>
                <div class="title">卡资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input"><input class="text-right" type="text" readonly v-model="memberInfo.Card_Money"/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo.Gift_Money}}元</p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input"><input class="text-right" type="text" readonly v-model="memberInfo.Card_PilePoint"/>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text" readonly/><i class="iconfont">></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="scroll-box">
                <div class="info-box">
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">应退金额：</div>
                            <div class="from-input">
                                <input type="text" v-model="amountMoney" readonly/>
                            </div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">实际退款金额：</div>
                            <div class="from-input">
                                <input type="text" keyBoard @focus="$event.target.select()" placeholder="请输入" v-model="actualMoney" oninput="value=value.replace(/[^\d.]/g,'')" />
                            </div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">原工本费：</div>
                            <div class="from-input">
                                <input type="text" @focus="$event.target.select()" v-model="memberInfo.Cost_Money" readonly />
                            </div>
                        </div>
                        <div class="td-5">
                            <div class="lable-txt">退工本费：</div>
                            <div class="from-input">
                                <input type="text" keyBoard placeholder="请输入" v-model="costMoney" oninput="value=value.replace(/[^\d.]/g,'')" />
                            </div>
                        </div>
                    </div>
                    <div class="title">付款方式</div>
                    <div class="pay-box">
                        <div class="pay-scroll"  v-scroll-anime="{up:'pay-up',down:'pay-down',liClass:'.item-li',marginH:8,isRow:false}">
                            <div class="item-li" v-show="item.PAY_NAME!='聚合支付'" :class="{selected:item.PAY_ID==SelectPayType.PAY_ID}" :aria-disabled="!memberInfo.Bestech_Card_KindID"  v-for="item in payType" :key="item" @click="payClick(item)">
                                {{item.PAY_NAME}}<i class="iconfont icon-gou1"></i>
                            </div> 
                        </div>
                        <div class="item-operation">
                            <div class="bnt-fangxiang" ref="pay-up"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="pay-down"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn" @click="back()">返回</button>
                <button class="btn selected" @click="ReturnCardClick()" >确定退卡</button>
            </div>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo.Coupons" ></ticket-model>
        </modal-load> 
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
    </div>
</template>

<script>
import { ticketModel,selectCardMemberInfo } from '../model'
import { newGuid } from '/src/common/index.js'

/**退卡 */
export default {
    name:'cardReturn',
    components:{ticketModel,selectCardMemberInfo},
    data(){
        return {
            /**卡号 */
            cardNo:'',
             /**内卡号 */
            cardSNR:'',
            /**卡id */
            card_AutoID:'',
            /**券列表 */
            ticketShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**支付方式 */
            payType:Object,
            /**付款方式 */
            SelectPayType:'',
            /**会员信息 */
            memberInfo:'',
            /**获取用户*/
            userInfo:'',
            /**券的数量 */
            couponsNum:'',
            /**应退金额 */
            amountMoney:'',
            /**实际退款金额 */
            actualMoney:'',
            /**退工本费 */
            costMoney:'',
        }
    },
    watch:{
        cardNo(){
            this.memberInfo='';
            this.amountMoney='';
        }
    },
     mounted(){
        this.$nextTick(()=> {
            this.userInfo=this.$auth.getUserInfo();
            this.$httpAES.post('Bestech.CloudPos.GetMemberPayments',{},'获取基础数据中...').then((data)=>{
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.payType=data.ResponseBody?.filter(it=>it.INTERFACE_TYPE!='11') ;
                    console.log(data)
                }
            }).catch(()=>{
                this.$alert('获取支付方式失败', "提示", {confirmButtonText: "确定"});
            });
        })
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.selectReadCard()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        ticketClick(){
            if(this.memberInfo!=''){
                this.ticketShow = true
            }
        },
        /**读取会员信息*/
        selectReadCard(){
            if(!this.cardNo && !this.cardSNR){
                this.$message.warning('请填写退卡卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetNotIssuedCard:true,
            }).then((data)=>{
                loading.close();
                this.actualMoney='',this.costMoney='',this.SelectPayType=''
                if(data.ResponseHeader.ResultCode==0){
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                   
                }else{
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                if(this.memberInfo.Gender==0){
                    this.memberInfo.Gender='男'
                }else{
                    this.memberInfo.Gender='女'
                }
                this.amountMoney = this.memberInfo.Card_Money;
                //默认实际退款金额:卡内余额减去赠送的金额
                this.actualMoney = Number((Number(this.memberInfo.Card_Money) - Number(this.memberInfo.Gift_Money)).toFixed(2));
            })
        },
        payClick(item){
            this.SelectPayType = item
        },
        /**确定退卡 */
        ReturnCardClick(){
            if(this.memberInfo=='' || this.memberInfo==undefined){
                this.$message.warning('请确认获取到卡号信息')
                return
            }
            let money=Number((Number(this.memberInfo.Card_Money) - Number(this.memberInfo.Gift_Money)).toFixed(2));
            if( Number(this.actualMoney)>money){
                this.$message.warning('实际退款金额不能超过原金额')
                 return
            }
            if(Number(this.costMoney)>Number(this.memberInfo.Cost_Money)){
                this.$message.warning('退款工本费不可大于原工本费')
                return
            }
            let _money = Number(this.costMoney) + Number(this.actualMoney)
            if((this.SelectPayType==''|| this.SelectPayType.length==0) && _money>0){
                this.$message.warning('请选择退款方式')
                return
            }
            if(this.actualMoney==''){
                this.actualMoney=0
            }
            let cardOperationEvent={
                Card_AutoID:this.memberInfo.Bestech_Card_AutoID, //卡主键
                Card_No:this.cardNo,//卡号
                Cost_Money: Number(this.costMoney),// 工本费
                Refund_PayType:this.SelectPayType.INTERFACE_TYPE, //退款方式类型
                Refund_PayID:this.SelectPayType.PAY_CODE, //退卡支付方式编号
                Refund_Money:this.amountMoney, //退卡金额
                Refund_FactMoney: Number(this.actualMoney), //实际退卡金额
                Refund_Point:this.memberInfo.Card_PilePoint, //退积分
                Pos_Czy:this.userInfo?.Login_Name,//操作员
                Pos_Name:this.userInfo?.Site_Name, //站点
            }
            let param={
                Event_Enum:3, //1：挂失，2：取消挂失，3：退卡，4 :冻结卡（停用），5：解冻卡（撤销停用）
                Event_Type:'退卡',
                CardOperationEvent:cardOperationEvent
            }
            const loading = this.$loading({
                text: "退卡中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log("退卡传入参数",param)
            this.$httpAES.post("Bestech.CloudPos.CardOperation",param).then((data)=>{
                loading.close();
                console.log('返回信息:',data)
                if(data.ResponseHeader.ResultCode==0){
                    this.$message.success("退卡完成");
                    this.reload()
                }else{
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('退卡失败：'+e);
                console.log('退卡失败：'+e);
            })
        },
        /*刷新当前页面 */
        reload(){
             this.$emit("reload");
        },
    }
}
</script>

<style lang="scss">
@import "./cardReturn.scss";
</style>